.downloader {
  width: 500px;
  min-height: 128px;
  position: fixed;
  left: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
}
.downloader .card-header {
  color: #fff;
  background-color: #fc441c;
}
.downloader .card .list-group {
  background-color: #e3dedd;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
