.home {
  display: flex;
  flex-wrap: wrap;
  width: 99.4%;
  margin-top: 6%;
  margin-bottom: 2%;
}
.banner {
  background-image: url("../assets/coverPage.jpg");
  background-position: center;
  width: 60%;
  min-height: calc(100vh);
  min-width: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.form {
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  width: 40%;
}
.headerForm {
  color: #183880;
}
.body {
  color: black;
  margin-bottom: 0.2rem;
}
.check {
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 50.5em) {
  .banner {
    background-image: url("../assets/coverPage.jpg");
    background-position: center;
    width: 99.4%;
    min-height: calc(100vh);
    min-width: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .form {
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    width: 99.4%;
  }
}
